export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const CONTENT_JSON_URL = process.env.REACT_APP_CONTENT_JSON_URL || "";
export const BASE_URL = process.env.REACT_APP_BASE_URL || "/";
export const SENTRY_DSN_URL = process.env.REACT_APP_SENTRY_DSN_URL;
export const SHARE_URL = process.env.REACT_APP_SHARE_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";

export const EMAIL_REGEXP = "\\S+@\\S+\\.\\S+";
export const PASSWORD_PATTERN =
	"^((?=.*?\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[-!@#$%^&*()_+|~=`{}\\[\\]:\";'<>?,.\\/])).{8,}$";

export const PASSWORD_REQUIREMENTS =
	"Password must include 8 characters, including 1 upper case character, 1 number and 1 special character";
export const DISPLAY_NAME_PATTERN = "^[a-zA-Z\\s\\d\\-'_\"]{1,40}$";

export const REACT_APP_SSO_CLIENT_ID = process.env.REACT_APP_SSO_CLIENT_ID || "";
export const REACT_APP_SSO_REDIRECT_URI = process.env.REACT_APP_SSO_REDIRECT_URI || "";
export const REACT_APP_SSO_URL = process.env.REACT_APP_SSO_URL || "";
export const LOGOUT_LINK = process.env.REACT_APP_LOGOUT_LINK;

export const HEADER_WITH_SUB_NAV_HEIGHT = 113;
export const HEADER_HEIGHT = 72;
export const BANNER_HEIGHT = 44;
export const MOBILE_BANNER_HEIGHT = 36;

export const MAX_FILE_SIZE = 2097152;
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY || "";

export const REACT_SEASON_YEAR = process.env.REACT_SEASON_YEAR || 2025;
export const REACT_FINALS_WEEK_1 = process.env.REACT_FINALS_WEEK_1 || 28;
export const REACT_FINALS_WEEK_2 = process.env.REACT_FINALS_WEEK_2 || 29;
export const REACT_SEMI_FINALS = process.env.REACT_SEMI_FINALS || 30;
export const REACT_GRAND_FINALS = process.env.REACT_GRAND_FINALS || 31;
export const REACT_APP_SHOW_NRLW_TILE = process.env.REACT_APP_SHOW_NRLW_TILE || false;

export * from "./sportbet";
