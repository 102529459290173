import {makeAutoObservable, action, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {IUser, type IUserStore} from "data/stores/user/user.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import type {ChangeEvent} from "react";
import {toFormData} from "data/utils/toFormData";
import {RequestState, ModalType, RoundStatus} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {extractErrorMessage} from "data/utils";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";

export interface ITipsProfileController extends ViewController {
	readonly i18n: ILocalizationStore;

	get user(): IUser;

	get showBettingVisibleButton(): boolean;

	switchChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class TipsProfileController implements ITipsProfileController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _timerId?: ReturnType<typeof setTimeout>;

	get user() {
		return this._userStore.user!;
	}

	get isUserOverSponsorMinAge() {
		return this._userStore.isUserOverSponsorMinAge;
	}

	get roundStatus() {
		return this._roundStore.selectedRound?.status;
	}

	get isComplete() {
		return this.roundStatus === RoundStatus.Complete;
	}

	get showBettingVisibleButton() {
		return this.isUserOverSponsorMinAge && !this.isComplete;
	}

	switchChangeHandler = async (event: ChangeEvent<HTMLInputElement>) => {
		try {
			const isBettingVisible = event.target.checked;

			runInAction(() => {
				this._requestState = RequestState.PENDING;
			});

			const formData = toFormData({
				isBettingVisible,
			});

			await this._userStore.update(formData);

			this.onSuccess();
		} catch (error) {
			runInAction(() => {
				this._requestState = RequestState.ERROR;
			});

			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(error as AxiosError<IApiResponse>),
			});
		}
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;

		this._timerId = setTimeout(() => {
			this._requestState = RequestState.IDLE;
		}, 10000); // 10 seconds
	};

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.RoundsStore) private _roundStore: IRoundsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}
}
