import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {burgerMenuLinks, subNavigation} from "data/constants/navigation";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IUserStore} from "data/stores/user/user.store";
import {IBurgerNavItem, type ISubNavItem} from "data/types/navigation";
import {REACT_APP_SHOW_NRLW_TILE} from "data/constants";

export interface ITippingLayoutController extends ViewController {
	subNavItems: ISubNavItem[];
	burgerNavItems: IBurgerNavItem[];
}

@injectable()
export class TippingLayoutController implements ITippingLayoutController {
	get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	get subNavItems() {
		return subNavigation
			.filter(({isPrivate}) => (isPrivate ? this.isAuthorized : true))
			.filter(
				(item) =>
					item.defaultValue !== "NRLW Tipping" || REACT_APP_SHOW_NRLW_TILE === "true"
			);
	}

	get burgerNavItems() {
		return burgerMenuLinks.filter(({isPrivate}) => (isPrivate ? this.isAuthorized : true));
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}
}
