import {DateTime} from "luxon";
import {action, makeAutoObservable, observable, runInAction, computed} from "mobx";
import {inject, injectable} from "inversify";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {IAuthApiProvider} from "data/providers/api/auth.api.provider";
import type {IUserApiProvider} from "data/providers/api/user.api.provider";
import type {
	IForgotPasswordPayload,
	IPasswordApiProvider,
	IResetPasswordPayload,
} from "data/providers/api/password.api.provider";
import type {ILoginPayload, INrlIdApiProvider} from "data/providers/api/nrl_id.api.provider";
import {Gender} from "data/enums";
import {get} from "lodash";
import {SportbetAgeLimit} from "data/constants";

export interface IUser {
	id: number;
	email: string;
	isNotificationsEnabled: boolean;
	isPushEnabled: boolean;
	firstName: string;
	lastName: string;
	displayName: string;
	avatarVersion: number;
	supportedSquadId: number | null;
	recovered: boolean;
	isBettingVisible: boolean;
	birthday: string;
	gender: Gender;
	autopickPreference?: string;
}

export interface IOldUser {
	displayName: string;
	country: string;
	state: string;
	id: number;
	avatarVersion: number;
	gender: string | null;
}

export interface INRLIdProfile {
	id: number;
	email: string;
	firstName: string;
	lastName: string;
	birthday: string | null;
	supportedClub: number | null;
	gender: string | null;
}

export interface IUserStore {
	get user(): IUser | null;
	get oldUser(): IOldUser | null;
	get isUserOverSponsorMinAge(): boolean;
	get nrlIdProfile(): INRLIdProfile | null;
	get isAuthorized(): boolean;
	get wasLoggedOut(): boolean;

	forgotPassword(payload: IForgotPasswordPayload): Promise<AxiosResponse<void>>;
	resetPassword(payload: IResetPasswordPayload): Promise<AxiosResponse<void>>;
	register(payload: FormData): Promise<void>;
	recover(payload: FormData): Promise<void>;
	update(payload: FormData): Promise<void>;
	deactivate(): Promise<void>;
	login(payload: ILoginPayload): Promise<void>;
	logout(): Promise<void>;
	requestUser(): Promise<void>;
}

@injectable()
export class UserStore implements IUserStore {
	@observable private _user: IUser | null = null;
	@observable private _oldUser: IOldUser | null = null;
	@observable private _nrlIdProfile: INRLIdProfile | null = null;
	@observable private _wasLoggedOut = false;

	constructor(
		@inject(Bindings.AuthApiProvider) private _authApi: IAuthApiProvider,
		@inject(Bindings.UserApiProvider) private _userApi: IUserApiProvider,
		@inject(Bindings.PasswordApiProvider) private _passwordApi: IPasswordApiProvider,
		@inject(Bindings.NrlIdApiProvider) private _nrlIdApi: INrlIdApiProvider
	) {
		makeAutoObservable(this);
	}

	// user has to exist and recovered must be true to be authorized.
	get isAuthorized() {
		return get(this.user, "recovered", false);
	}

	get wasLoggedOut() {
		return this._wasLoggedOut;
	}

	get user() {
		return this._user;
	}

	get oldUser() {
		return this._oldUser;
	}

	@computed get isUserOverSponsorMinAge() {
		const birthday = this._user?.birthday || "";

		if (!birthday) return false;

		return (
			DateTime.fromFormat(birthday, "yyyy-MM-dd").diffNow("years").years < -SportbetAgeLimit
		);
	}

	get nrlIdProfile() {
		return this._nrlIdProfile;
	}

	@action
	async requestUser() {
		const response = await this._userApi.user();
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
		});
	}

	@action
	async login(payload: ILoginPayload) {
		const response = await this._nrlIdApi.login(payload);
		const {user, oldUser, nrlIdProfile} = response.data.success;

		runInAction(() => {
			this._user = user;
			this._oldUser = oldUser;
			this._nrlIdProfile = nrlIdProfile;
			this._wasLoggedOut = false;
		});
	}

	@action
	async register(payload: FormData) {
		const response = await this._nrlIdApi.register(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
			this._wasLoggedOut = false;
		});
	}

	@action
	async recover(payload: FormData) {
		const response = await this._nrlIdApi.recover(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
			this._wasLoggedOut = false;
		});
	}

	@action
	async update(payload: FormData) {
		const response = await this._userApi.update(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
		});
	}

	@action
	async logout() {
		await this._authApi.logout();

		runInAction(() => {
			this._user = null;
			this._wasLoggedOut = true;
		});
	}

	@action
	async deactivate() {
		await this._userApi.deactivateAccount();

		runInAction(() => {
			this._user = null;
			this._wasLoggedOut = true;
		});
	}

	forgotPassword(payload: IForgotPasswordPayload) {
		return this._passwordApi.forgotPassword(payload);
	}

	resetPassword(payload: IResetPasswordPayload) {
		return this._passwordApi.resetPassword(payload);
	}
}
