import React from "react";
import {observer} from "mobx-react";
import {MatchStatus} from "data/enums";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {useIsMobile} from "data/hooks/useIsMobile";
import {
	Complete,
	getPercents,
	getValue,
	ICardPredictorStats,
	InfoSection,
	InfoText,
	Live,
} from "views/components/tipping_card/card_predictor/card_predictor_stats/card_predictor_stats.component";
import {Exist} from "views/components/exist/exist.component";
import {RoundProgress} from "views/components/round_progress/round_progress";
import {ILocalizationController} from "views/controllers/localization/localization.controller";
import {ReactComponent as RestartIcon} from "assets/img/icons/restart.svg";

export const StreakCardStats: React.FC<ICardPredictorStats> = observer(
	({
		clickHandler,
		isScheduled,
		isPlaying,
		isComplete,
		homeSquad,
		awaySquad,
		awayScore,
		homeScore,
		hasPrediction,
	}) => {
		const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);
		const {isMobile} = useIsMobile();
		const progressWidth = isMobile ? 40 : 55;
		const strokeWidth = isMobile ? 4 : 5;
		const isShowResetIcon = hasPrediction && isScheduled;
		const Icon = isShowResetIcon ? RestartIcon : undefined;
		const className = isShowResetIcon ? "clickable" : "";

		return (
			<InfoSection
				onClick={clickHandler}
				tabIndex={isShowResetIcon ? 0 : -1}
				className={`stats-section streak ${className}`}>
				<Exist when={isScheduled}>
					<InfoText>{homeSquad.selected}%</InfoText>
					<RoundProgress
						width={progressWidth}
						strokeWidth={strokeWidth}
						awayColor={awaySquad.color}
						homeColor={homeSquad.color}
						percents={getPercents(awaySquad.selected, homeSquad.selected)}
						text={i18n.t("streak.progress.vs", "VS")}
						Icon={Icon}
					/>
					<InfoText>{awaySquad.selected}%</InfoText>
				</Exist>
				<Exist when={isPlaying}>
					<InfoText className={MatchStatus.Playing}>{getValue(homeScore)}</InfoText>

					<Live>{i18n.t("tips.card.live", "Live")}</Live>

					<InfoText className={MatchStatus.Playing}>{getValue(awayScore)}</InfoText>
				</Exist>
				<Exist when={isComplete}>
					<InfoText>{getValue(homeScore)}</InfoText>

					<Complete />

					<InfoText>{getValue(awayScore)}</InfoText>
				</Exist>
			</InfoSection>
		);
	}
);
