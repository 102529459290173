import {partial} from "lodash";
import React, {FC} from "react";
import {Button} from "@mui/material";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {IModalAutoPickController} from "./modal_auto_pick.controller";
import {
	ModalBase,
	ModalDesc,
	ModalTitle,
} from "views/components/modals/modal_base/modal_base.component";
import {ReactComponent as ModalIcon} from "assets/img/icons/modalProgress.svg";

const IconContainer = styled.div`
	margin-bottom: 18px;

	@media (min-width: 769px) {
		margin-bottom: 0;
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0;
	max-width: 380px;
	width: 100%;

	@media (min-width: 769px) {
		gap: 18px;
	}
`;

const SModalTitle = styled(ModalTitle)`
	margin-bottom: 6px;
`;

const Buttons = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 12px;
	border-radius: 4px;
	background: #f6f6f6;
	gap: 12px;
	margin-bottom: 12px;
`;

const SButton = styled(Button)<{isSelected: boolean}>`
	height: 50px;

	${({isSelected}) =>
		isSelected &&
		`
		background: #64ff32;
		box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
	`};

	&:hover {
		background: #64ff32;
	}

	&.sportsBet {
		display: grid;
		grid-template-columns: 48px 1fr 48px;
		border-width: 2px;
		border-color: ${({isSelected}) => (isSelected ? "#EFBF13" : "#0064a6")};
		background: #0064a6;
		color: #ffffff;
		padding: 0;

		img {
			width: 46px;
			height: 46px;
		}

		&:hover {
			border-color: #efbf13;
		}
	}

	&.no-sponsor {
		display: flex;
	}
`;

const HelpText = styled.div`
	font-size: 14px;
	margin-top: 8px;

	@media (min-width: 769px) {
		margin-top: 0;
	}
`;

export const ModalAutoPick: FC = observer(() => {
	const {
		isOpen,
		i18n,
		close,
		selectHandler,
		submitHandler,
		isDisabled,
		autoPickList,
		isSelected,
	} = useViewController<IModalAutoPickController>(Bindings.ModalAutoPickController);

	return (
		<ModalBase isOpen={isOpen} closeHandler={close}>
			<Container>
				<IconContainer>
					<ModalIcon />
				</IconContainer>

				<div>
					<SModalTitle>
						{i18n.t("modal.auto_pick.title", "Autopick your round")}
					</SModalTitle>

					<ModalDesc>
						{i18n.t(
							"modal.auto_pick.desc",
							"Choose the statistic you wish to autopick this round with"
						)}
					</ModalDesc>
				</div>

				<Buttons>
					{autoPickList.map(({value, locoKey, defaultLocoValue, icon, className}) => (
						<SButton
							isSelected={isSelected(value)}
							variant="outlined"
							color="secondary"
							onClick={partial(selectHandler, value)}
							key={locoKey}
							className={className}>
							{icon && <div />}

							{i18n.t(locoKey, defaultLocoValue)}

							{icon && <img src={icon} alt={i18n.t(locoKey, defaultLocoValue)} />}
						</SButton>
					))}
				</Buttons>

				<Button
					color="primary"
					fullWidth
					variant="contained"
					onClick={submitHandler}
					disabled={isDisabled}>
					{i18n.t("modal.auto_pick.submit", "Apply Autopick")}
				</Button>

				<HelpText>
					{i18n.t(
						"modal.auto_pick.alt",
						"Clicking on Apply auto-pick will update all tips for the round as per your selection"
					)}
				</HelpText>
			</Container>
		</ModalBase>
	);
});
