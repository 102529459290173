import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeagues, ILeaguesStore, IRecoverLeague} from "data/stores/leagues/leagues.store";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RequestState} from "data/enums";
import {isEmpty, isEqual, partial} from "lodash";
import {extractErrorMessage} from "data/utils";
import {useNavigate} from "react-router-dom";
import {type IUserStore} from "data/stores/user/user.store";
import {getLoginLink} from "data/utils/getLoginLink";
import {storageAvailable} from "data/utils/storageAvailable";

interface IProps {
	code?: string;
	navigate: ReturnType<typeof useNavigate>;
}

export interface IJoinLeaguesController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get leaguesForJoin(): ILeagues;
	get hasLeaguesForJoin(): boolean;
	get isLoadingLeaguesForJoin(): boolean;
	get leagueInvites(): ILeagues;
	get isLoadingLeagueInvites(): boolean;
	get hasLeagueInvites(): boolean;
	get recoverLeagues(): IRecoverLeague[];
	get isLoading(): boolean;
	get hasRecover(): boolean;

	loadMoreLeaguesForJoin: () => void;
	loadMoreLeagueInvites: () => void;
}

@injectable()
export class JoinLeaguesController implements IJoinLeaguesController {
	@observable _joiningLeagueId?: number;
	@observable _navigate!: ReturnType<typeof useNavigate>;

	@observable _requestStates = {
		general: RequestState.PENDING,
		joinLeagues: RequestState.PENDING,
		invites: RequestState.PENDING,
		join: RequestState.IDLE,
	};

	get leaguesForJoin() {
		return this._leaguesStore.leaguesForJoin;
	}

	get hasLeaguesForJoin() {
		return !isEmpty(this.leaguesForJoin.leagues);
	}

	get isLoadingLeaguesForJoin() {
		return isEqual(this._requestStates.joinLeagues, RequestState.PENDING);
	}

	get leagueInvites() {
		return this._leaguesStore.leagueInvites;
	}

	get hasLeagueInvites() {
		return !isEmpty(this.leagueInvites.leagues);
	}

	get isLoadingLeagueInvites() {
		return isEqual(this._requestStates.invites, RequestState.PENDING);
	}

	get recoverLeagues() {
		return this._leaguesStore.recoverLeagues;
	}

	get hasRecover() {
		return !isEmpty(this.recoverLeagues);
	}

	get isLoading() {
		return isEqual(this._requestStates.general, RequestState.PENDING);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	loadMoreLeaguesForJoin = () => {
		this.setPendingState("joinLeagues");

		this._leaguesStore
			.fetchMoreLeaguesForJoin()
			.then(() => this.createSuccessHandlerOf("joinLeagues"))
			.catch(partial(this.createErrorHandlerOf, "joinLeagues"));
	};

	loadMoreLeagueInvites = () => {
		this.setPendingState("invites");

		this._leaguesStore
			.fetchMoreLeagueInvites()
			.then(() => this.createSuccessHandlerOf("invites"))
			.catch(partial(this.createErrorHandlerOf, "invites"));
	};

	private setPendingState = (type: keyof typeof this._requestStates) => {
		this._requestStates[type] = RequestState.PENDING;
	};

	private createSuccessHandlerOf = (type: keyof typeof this._requestStates) => {
		this._requestStates[type] = RequestState.SUCCESS;
	};

	private createErrorHandlerOf = (
		type: keyof typeof this._requestStates,
		error: AxiosError<IApiResponse>
	) => {
		this._requestStates[type] = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action init({code, navigate}: IProps) {
		this._navigate = navigate;

		if (!this._userStore.isAuthorized && code && storageAvailable("sessionStorage")) {
			sessionStorage.setItem("redirectPath", `/comps/join/${code}`);
			window.location.href = getLoginLink(false);
			return;
		}

		Promise.all([
			this._leaguesStore
				.fetchLeaguesForJoin()
				.then(() => (this._requestStates.joinLeagues = RequestState.SUCCESS))
				.catch(() => (this._requestStates.joinLeagues = RequestState.ERROR)),
			this._leaguesStore
				.fetchLeagueInvites()
				.then(() => (this._requestStates.invites = RequestState.SUCCESS))
				.catch(() => (this._requestStates.invites = RequestState.ERROR)),
			this._leaguesStore.fetchRecoverLeagues(),
		])
			.then(() => this.createSuccessHandlerOf("general"))
			.catch(partial(this.createErrorHandlerOf, "general"));

		if (code) {
			this._modalsStore.showModal(ModalType.JOIN_LEAGUE_INVITE, {
				message: "",
				leagueCode: code,
			});
		}
	}

	dispose() {
		this._leaguesStore.clearStore();
	}
}
