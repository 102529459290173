import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {ITournament, type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type IPredictionsStore} from "data/stores/predictions/predictions.store";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {isNumber} from "lodash";

export interface ITipsController extends ViewController {
	readonly i18n: ILocalizationStore;

	get tournaments(): ITournament[];

	get tournamentMarginIsValid(): boolean;
}

@injectable()
export class TipsController implements ITipsController {
	get tournaments() {
		return this._roundsStore.selectedRound?.tournaments || [];
	}

	get tournamentMarginIsValid() {
		const marginTournament = this.tournaments.find((tournament) => tournament.isMargin);

		if (marginTournament) {
			const prediction = this._predictionsStore.getPredictionByTournamentId(
				marginTournament.id
			);
			return isNumber(prediction?.margin) && prediction?.margin !== 0;
		}

		return false;
	}

	constructor(
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
	}
}
