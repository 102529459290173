import React, {FC} from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {IconButton} from "@mui/material";
import {Link, NavLink} from "react-router-dom";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {HEADER_HEIGHT, HEADER_WITH_SUB_NAV_HEIGHT} from "data/constants";
import {IBurgerNavItem, ISubNavItem} from "data/types/navigation";
import {useIsMobile} from "data/hooks/useIsMobile";
import {SubNav} from "views/components/sub_nav/sub_nav.component";
import {type IHeaderController} from "views/components/header/header/header.controller";
import {BurgerNav} from "views/components/header/burger_nav/burger_nav.component";
import {MobileHeader} from "views/components/header/mobile_header/mobile_header.component";
import {Exist} from "views/components/exist/exist.component";
import {ReactComponent as MenuIcon} from "assets/img/icons/menu.svg";
import {ReactComponent as AccountIcon} from "assets/img/icons/blackAccount.svg";
import LogoutIcon from "@mui/icons-material/Logout";

const Wrapper = styled.header<{isHideSubNav: boolean}>`
	width: 100%;
	display: flex;
	flex-direction: column;
	position: sticky;
	top: 0;
	z-index: 10;
	background: ${({theme}) => theme.palette.header.bg};
	height: ${({isHideSubNav}) => (isHideSubNav ? HEADER_HEIGHT : HEADER_WITH_SUB_NAV_HEIGHT)}px;
`;

const Container = styled.div`
	display: flex;
	align-items: center;
	max-width: 1280px;
	margin: 0 auto;
`;

const Navigation = styled.div`
	padding: 10px 16px 14px;
	border-bottom: 1px solid ${({theme}) => theme.palette.header.navBorderBottom};
`;

const MenuButton = styled(IconButton)`
	margin-right: 6px;
`;

const NavItems = styled.div`
	display: flex;
	margin-left: auto;
	gap: 8px;
`;

const NavItem = styled(NavLink)`
	display: flex;
	align-items: center;
	padding: 0 8px;
	border-radius: 4px;
	gap: 4px;

	&:hover {
		background: rgba(0, 0, 0, 0.04);
	}
`;

const MyAccountText = styled.div`
	@media (max-width: 380px) {
		display: none;
	}
`;

interface IHeader {
	LogoIcon?: FC;
	subNavItems: ISubNavItem[];
	burgerNavItems: IBurgerNavItem[];
	/**
	 * Only the sticky burger menu will be displayed on the phone
	 */
	isHideMobileMenu?: boolean;
	isHideSubNav?: boolean;
}

export const Header: React.FC<IHeader> = observer(
	({LogoIcon, subNavItems, burgerNavItems, isHideMobileMenu = false, isHideSubNav = false}) => {
		const {
			isOpen,
			toggleMenu,
			closeHandler,
			isAuthorized,
			i18n,
			logoutHandler,
			menuButtonRef,
			closeButtonRef,
		} = useViewController<IHeaderController>(Bindings.HeaderController);
		const {isMobile} = useIsMobile();

		if (isHideMobileMenu && isMobile) {
			return (
				<MobileHeader
					isOpen={isOpen}
					closeHandler={closeHandler}
					burgerNavItems={burgerNavItems}
					toggleMenu={toggleMenu}
					menuButtonRef={menuButtonRef}
					closeButtonRef={closeButtonRef}
				/>
			);
		}

		return (
			<Wrapper isHideSubNav={isHideSubNav}>
				<Navigation>
					<Container>
						<MenuButton
							onClick={toggleMenu}
							aria-label="Menu"
							aria-expanded={isOpen}
							aria-controls="burger-menu"
							ref={menuButtonRef}>
							<MenuIcon />
						</MenuButton>

						{LogoIcon && (
							<Link to="/" aria-label="NRL Tipping Home">
								<LogoIcon />
							</Link>
						)}

						<Exist when={isAuthorized}>
							<NavItems>
								<NavItem to="/my-account">
									<AccountIcon />

									<MyAccountText>
										{i18n.t("navigation.my_account.copy", "My Account")}
									</MyAccountText>
								</NavItem>

								<IconButton
									aria-label="Logout"
									title="Logout"
									onClick={logoutHandler}>
									<LogoutIcon />
								</IconButton>
							</NavItems>
						</Exist>
					</Container>
				</Navigation>

				<Exist when={!isHideSubNav}>
					<SubNav items={subNavItems} />
				</Exist>
				<BurgerNav
					isOpen={isOpen}
					closeHandler={closeHandler}
					items={burgerNavItems}
					closeButtonRef={closeButtonRef}
				/>
			</Wrapper>
		);
	}
);
