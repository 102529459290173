import {DateTime} from "luxon";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {action, computed, makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {REACT_APP_SSO_REDIRECT_URI, SportbetAgeLimit} from "data/constants";
import {RequestState} from "data/enums";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import type {IUserApiProvider, IUsername} from "data/providers/api/user.api.provider";
import {extractErrorMessage} from "data/utils";
import {identity, pickBy, get} from "lodash";
import React, {ChangeEvent} from "react";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {IAutoPick} from "data/types/game";
import {IRecoverPayload} from "data/providers/api/nrl_id.api.provider";
import {toFormData} from "data/utils/toFormData";
import {type IAutoPickStore} from "data/stores/auto_pick/auto_pick.store";

interface IRecoverForm extends HTMLFormElement {
	displayName: HTMLInputElement;
	sponsorOptIn: HTMLInputElement;
	terms: HTMLInputElement;
	autoPick: HTMLSelectElement;
}

interface IInit {
	code: string;
}

export interface IRecoverUserController extends ViewController<IInit> {
	readonly i18n: ILocalizationStore;

	get state(): IRecoverFormState;
	get user(): IUser | null;
	get error(): Record<string, string> | null;
	get isFormDisabled(): boolean;
	get teamNameChecking(): boolean;
	get autoPickList(): IAutoPick[];
	get isShowSponsor(): boolean;
	get isLoaded(): boolean;

	handleFormOnChange: (
		name: keyof IRecoverFormState
	) => (e: ChangeEvent<HTMLInputElement>) => void;
	handleFormSubmit: (event: React.SyntheticEvent<IRecoverForm>) => void;
	checkTeamName: (e: ChangeEvent<HTMLInputElement>) => void;
}

interface IRecoverFormState {
	sponsorOptIn: boolean;
	terms: boolean;
	displayName: string;
	autoPick: string;
}

@injectable()
export class RecoverUserController implements IRecoverUserController {
	@observable private _code: string = "";
	@observable _requestState: RequestState = RequestState.IDLE;
	@observable private _teamNameCheck: RequestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _hasUserStoreInfo = false;
	@observable private _state: IRecoverFormState = {
		sponsorOptIn: false,
		displayName: "",
		terms: false,
		autoPick: "",
		// date: "",
	};

	get state() {
		return this._state;
	}

	get user() {
		return this._userStore.user;
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isFormDisabled() {
		return this._requestState === RequestState.PENDING;
	}

	get teamNameChecking() {
		return [RequestState.PENDING].includes(this._teamNameCheck);
	}

	get isLoaded() {
		return this._hasUserStoreInfo;
	}

	@computed get isShowSponsor() {
		const birthday = this._userStore.nrlIdProfile?.birthday || "";
		return (
			DateTime.fromFormat(birthday, "yyyy-MM-dd").diffNow("years").years < -SportbetAgeLimit
		);
	}

	get autoPickList() {
		return this._autoPickStore.getAutoPickList(
			this._autoPickStore.registrationList,
			this.isShowSponsor
		);
	}

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.UserApiProvider) private _userApi: IUserApiProvider,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.AutoPickStore) private _autoPickStore: IAutoPickStore
	) {
		makeAutoObservable(this);
	}

	async init(params: IInit) {
		this._code = params.code;

		if (this.user) {
			const userPrevAutoPickPreference = get(this.user, "autopickPreference");
			const userPrevDisplayName = get(this.user, "displayName");

			if (userPrevDisplayName) {
				this._state.displayName = userPrevDisplayName;
				void this.checkTeamName(userPrevDisplayName);
			}

			if (userPrevAutoPickPreference) {
				const isValidPreference = this.autoPickList.some(
					(pick) => pick.value === userPrevAutoPickPreference
				);

				if (isValidPreference) {
					this._state.autoPick = userPrevAutoPickPreference;
				}
			}

			this._hasUserStoreInfo = true;
		} else await this.login();
	}

	login = async () => {
		try {
			await this._userStore.login({
				code: this._code,
				codeVerifier: localStorage.getItem("code_verifier") || "",
				redirectUrl: REACT_APP_SSO_REDIRECT_URI,
			});
		} catch (error) {
			this.onError(error as AxiosError<IApiResponse>);
		} finally {
			this._hasUserStoreInfo = true;
		}
	};

	private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._errorMsg = extractErrorMessage(error);
	};

	private clearState = () => {
		this._errorMsg = null;
		this._errorPlace = "";
		this._requestState = RequestState.IDLE;
	};

	handleFormOnChange = (name: keyof IRecoverFormState) => (e: ChangeEvent<HTMLInputElement>) => {
		this.clearState();

		this._state = {
			...this._state,
			[name]: e.target.value,
		};
	};

	checkTeamName = async (input: ChangeEvent<HTMLInputElement> | string) => {
		this._teamNameCheck = RequestState.PENDING;

		const value = typeof input === "string" ? input : input.target.value;

		const payload: IUsername = {
			displayName: value.trim(),
		};

		try {
			await this._userApi.checkRecoveredUsername(payload);
			this._errorMsg = "";
			this._teamNameCheck = RequestState.SUCCESS;
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this._teamNameCheck = RequestState.ERROR;
			const msg = err.response?.data.errors[0].message || "";
			this._errorPlace = "displayName";
			this._errorMsg = msg;
		}
	};

	private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	hasValidationError = (event: React.SyntheticEvent<IRecoverForm>) => {
		const {displayName, terms, autoPick} = event.currentTarget;

		const validateList = [
			{
				field: displayName,
				error: this.i18n.t(
					"registration.sign_up.display_name_error",
					"Please provide your team name"
				),
				place: "displayName",
			},
			{
				field: terms,
				error: this.i18n.t("registration.sign_up.terms_error", "Please check terms"),
				place: "terms",
			},
			{
				field: autoPick,
				error: this.i18n.t(
					"registration.sign_up.auto_pick_error",
					"Please select Auto-pick logic"
				),
				place: "autoPick",
			},
		];

		return validateList.find(({field, error, place}) => {
			if (!field) {
				return false;
			}
			return field.checkValidity() ? false : this.reportError(error, place);
		});
	};

	@action handleFormSubmit = (event: React.SyntheticEvent<IRecoverForm>) => {
		event.preventDefault();

		const {sponsorOptIn, displayName, autoPick} = event.currentTarget;

		if (this.hasValidationError(event)) {
			return;
		}

		this._requestState = RequestState.PENDING;

		const params = pickBy(
			{
				displayName: displayName.value.trim(),
				autopickPreference: autoPick.value,
			},
			identity
		) as unknown as IRecoverPayload;

		const formData = toFormData({
			...params,
			sponsorOptIn: sponsorOptIn.checked,
		});

		return this._userStore
			.recover(formData)
			.then(() => {
				this._requestState = RequestState.SUCCESS;
			})
			.catch(this.onError);
	};
}
