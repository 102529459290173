import {useRef, MutableRefObject} from "react";
import {makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {LOGOUT_LINK} from "data/constants";

export interface IHeaderController extends ViewController {
	readonly i18n: ILocalizationStore;
	menuButtonRef: MutableRefObject<HTMLButtonElement | null>;
	closeButtonRef: MutableRefObject<HTMLButtonElement | null>;

	get user(): IUser | null;
	get isOpen(): boolean;
	get isAuthorized(): boolean;
	closeHandler(): void;
	toggleMenu(): void;
	logoutHandler(): void;
}

@injectable()
export class HeaderController implements IHeaderController {
	@observable private _disposer?: ReturnType<typeof reaction>;
	@observable private _isOpen = false;
	@observable public menuButtonRef = useRef<HTMLButtonElement>(null);
	@observable public closeButtonRef = useRef<HTMLButtonElement>(null);

	get user() {
		return this._userStore.user;
	}

	get isOpen() {
		return this._isOpen;
	}

	get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore
	) {
		makeAutoObservable(this);

		this._disposer = reaction(
			() => this.isOpen,
			(isOpen) => {
				const rootElement = document.querySelector("body");

				if (rootElement) {
					rootElement.style.overflow = isOpen ? "hidden" : "initial";
				}
			}
		);
	}

	private _handleKeyDown = (event: KeyboardEvent) => {
		if (event.key === "Escape" && this._isOpen) {
			this.closeHandler();
		}
	};

	toggleMenu = () => {
		const wasOpen = this._isOpen;
		this._isOpen = !this._isOpen;

		if (this._isOpen) {
			document.addEventListener("keydown", this._handleKeyDown);

			if (this.closeButtonRef.current) {
				setTimeout(() => {
					this.closeButtonRef.current?.focus();
				}, 100);
			}
		} else if (wasOpen) {
			document.removeEventListener("keydown", this._handleKeyDown);
		}
	};

	closeHandler = () => {
		if (this._isOpen) {
			document.removeEventListener("keydown", this._handleKeyDown);
		}

		this._isOpen = false;

		if (this.menuButtonRef.current) {
			setTimeout(() => {
				this.menuButtonRef.current?.focus();
			}, 100);
		}
	};

	dispose() {
		this._disposer?.();
	}

	logoutHandler = async () => {
		await this._userStore.logout();
		window.location.assign(LOGOUT_LINK as string);
	};
}
