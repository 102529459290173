import {inject, injectable} from "inversify";
import {AxiosResponse} from "axios";
import type {TUserResponse} from "data/providers/api/user.api.provider";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {INRLIdProfile, IOldUser, IUser} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";

export interface ILoginPayload {
	code: string;
	codeVerifier: string;
	redirectUrl: string;
}

type TLoginResponse = IApiResponse<{
	user: IUser | null;
	oldUser: IOldUser | null;
	nrlIdProfile: INRLIdProfile | null;
}>;

export interface IRegistrationPayload {
	displayName: string;
	code: string;
	codeVerifier: string;
	country: string;
	state?: string;
	redirectUrl: string;
	gender?: string;
	sponsorOptIn: boolean;
	avatar?: File;
	supportedSquadId?: number;
}

export interface IRecoverPayload {
	sponsorOptIn: boolean;
	displayName: string;
	autopickPreference: number;
}

export interface INrlIdApiProvider {
	login: (params: ILoginPayload) => Promise<AxiosResponse<TLoginResponse>>;
	register: (params: FormData) => Promise<AxiosResponse<TUserResponse>>;
	recover: (params: FormData) => Promise<AxiosResponse<TUserResponse>>;
}

@injectable()
export class NrlIdApiProvider implements INrlIdApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	login = (params: ILoginPayload) => this._http.post<TLoginResponse>("nrl-id/login", params);

	register = (params: FormData) => this._http.post<TUserResponse>("nrl-id/register", params);

	recover = (params: FormData) => this._http.post<TUserResponse>("user/recover", params);
}
