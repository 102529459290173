import React from "react";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ILastMeetings} from "data/stores/tournament_stats/tournament_stats.store";
import {FormGuide} from "views/components/form_guide/form_guide.component";
import {PreviousMeetings} from "views/components/previous_meetings/previous_meetings.component";
import {TournamentStatsCircle} from "views/components/tournament_stats_circle/tournament_stats_circle.component";
import {ExternalLink} from "views/components/links/links.component";
import {KeyChanges} from "views/components/modals/modal_game_stats/game_stats_card/key_changes/key_changes.component";
import {Exist} from "views/components/exist/exist.component";
import {ITeamStatsController} from "./team_stats.controller";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 18px 16px;
	min-height: 200px;
`;

const Title = styled.div`
	color: ${({theme}) => theme.palette.statsModal.color};
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	padding: 6px 0;
`;

const SectionTitle = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 11px;
	font-weight: 500;
	line-height: 12px;
	padding: 6px 0;
`;

const Link = styled(ExternalLink)`
	display: flex;
	align-items: center;
	gap: 1px;
	border-radius: 4px;
	background: var(--Primary-Magic, #64ff32);
	background: ${({theme}) => theme.palette.statsModal.linkBg};
	padding: 5px 6px;
	text-transform: uppercase;
	letter-spacing: 1.25px;

	svg {
		font-size: 12px;
	}

	:focus-visible {
		outline: -webkit-focus-ring-color auto 1px;
	}
`;

interface ITeamStats {
	tournamentId: number;
	homeSquadId: number;
	awaySquadId: number;
	lastMeetings: ILastMeetings[];
}

export const TeamStats: React.FC<ITeamStats> = observer(
	({tournamentId, homeSquadId, awaySquadId, lastMeetings}) => {
		const {i18n, hasKeyChanges} = useViewController<ITeamStatsController>(
			Bindings.TeamStatsController,
			{tournamentId}
		);

		return (
			<Wrapper>
				<Title>
					{i18n.t(
						"modal.game_stats.title",
						"Chances are you’re about to lose. For free and confidential support call 1800 858 858 or visit gamblinghelponline.org.au"
					)}
				</Title>

				<SectionTitle>
					<div>{i18n.t("modal.game_stats.stats_title", "Key Stats")}</div>
					<Link href="https://www.nrl.com/draw">
						{i18n.t("modal.game_stats.match_centre", "Match Centre")}
						<ArrowForwardIcon />
					</Link>
				</SectionTitle>

				<FormGuide tournamentId={tournamentId} />

				<PreviousMeetings
					awaySquadId={awaySquadId}
					homeSquadId={homeSquadId}
					list={lastMeetings}
				/>

				<TournamentStatsCircle tournamentId={tournamentId} />

				<SectionTitle>
					<div>
						<Exist when={hasKeyChanges}>
							{i18n.t("modal.game_stats.key_changes", "Key Changes")}
						</Exist>
					</div>
					<Link href="https://www.nrl.com/casualty-ward/?competition=111">
						{i18n.t("modal.game_stats.injury_news", "injury news")}
						<ArrowForwardIcon />
					</Link>
				</SectionTitle>

				<KeyChanges tournamentId={tournamentId} />
			</Wrapper>
		);
	}
);
