import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {flatMap, get} from "lodash";
import {DateTime} from "luxon";
import {FC} from "react";
import {MatchStatus, MatchType, TournamentIconType} from "data/enums";
import {nth} from "data/utils";
import {tournamentIcons, tournamentTitle} from "data/constants/game";
import type {IRoundsStore, ITournament} from "data/stores/rounds/rounds.store";
import {Bindings} from "data/constants/bindings";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IPrediction} from "data/stores/predictions/predictions.store";
import {IStreakPrediction} from "data/stores/streak_predictions/streak_predictions.store";

interface ITournamentDataSquad extends ISquad {
	selected: number;
	position: number;
	Icon: FC;
	color: string;
	isChecked: boolean;
	title: string;
}

export interface ITournamentData extends ITournament {
	date: string;
	isDisabled: boolean;
	homeSquad: ITournamentDataSquad;
	awaySquad: ITournamentDataSquad;
	isScheduled: boolean;
	isPlaying: boolean;
	isComplete: boolean;
	isShowLadderPosition: boolean;
	hasTeamSelected: boolean;
}

export interface ITournamentStore {
	getTournamentDataById: (
		tournamentId: number | null,
		prediction?: IPrediction
	) => ITournamentData | null;
	getTournamentIconType: (
		tournament: ITournament,
		squadId: number,
		prediction?: IPrediction
	) => TournamentIconType;
	getStreakTournamentIconType: (
		tournament: ITournament,
		squadId: number,
		prediction?: IStreakPrediction
	) => TournamentIconType;
	getTournamentById: (id: number) => ITournament | undefined;
	getIsShowOddsTournament(tournament: ITournament | null, isShowMatchOdds: boolean): boolean;
	getStreakTournamentDataById: (
		tournamentId: number | null,
		prediction?: IStreakPrediction
	) => ITournamentData | null;
}

@injectable()
export class TournamentStore implements ITournamentStore {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	private getIsShowLadderPosition(tournamentType: MatchType | null) {
		if (!this._roundsStore.isFinishFirstRound) {
			return false;
		}

		if (this._roundsStore.selectedRound?.id !== this._roundsStore.actualRound?.id) {
			return false;
		}

		return tournamentType === MatchType.Reg;
	}

	getTournamentDataById(tournamentId: number | null, prediction?: IPrediction) {
		const tournament = get(this._roundsStore.selectedRound, "tournaments", []).find(
			(tournament) => tournament.id === tournamentId
		);

		if (!tournament) {
			return null;
		}

		const homeSquad = this._squadsStore.getSquadById(tournament.homeSquadId);
		const awaySquad = this._squadsStore.getSquadById(tournament.awaySquadId);
		const date = DateTime.fromISO(tournament.date);

		const homeIconType = this.getTournamentIconType(
			tournament,
			tournament.homeSquadId,
			prediction
		);

		const awayIconType = this.getTournamentIconType(
			tournament,
			tournament.awaySquadId,
			prediction
		);

		const isDisabled = tournament.status !== MatchStatus.Scheduled;
		const homeSquadIsChecked = prediction?.squadId === tournament.homeSquadId;
		const awaySquadIsChecked = prediction?.squadId === tournament.awaySquadId;
		const isShowLadderPosition = this.getIsShowLadderPosition(tournament.type);

		return {
			...tournament,
			date: date.toFormat(`ccc, d'${nth(date.day)}' LLL h'.'mm a`),
			isDisabled,
			homeSquad: {
				...homeSquad,
				selected: Math.round(tournament.homePicksPercentage),
				position: get(homeSquad?.ladder, "position", 0),
				Icon: tournamentIcons[homeIconType],
				color: homeSquad?.color,
				isChecked: homeSquadIsChecked,
				title: this.i18n.t(tournamentTitle[homeIconType]),
			},
			awaySquad: {
				...awaySquad,
				selected: Math.round(tournament.awayPicksPercentage),
				position: get(awaySquad?.ladder, "position", 0),
				Icon: tournamentIcons[awayIconType],
				color: awaySquad?.color,
				isChecked: awaySquadIsChecked,
				title: this.i18n.t(tournamentTitle[awayIconType]),
			},
			isComplete: tournament.status === MatchStatus.Complete,
			isPlaying: tournament.status === MatchStatus.Playing,
			isScheduled: tournament.status === MatchStatus.Scheduled,
			isShowLadderPosition,
			hasTeamSelected: homeSquadIsChecked || awaySquadIsChecked,
		} as ITournamentData;
	}

	private getScheduledTournamentIconType(isSelected: boolean) {
		if (isSelected) {
			return TournamentIconType.CHECK;
		}

		return TournamentIconType.UN_CHECK;
	}

	private getPlayingTournamentIconType(isSelected: boolean) {
		if (isSelected) {
			return TournamentIconType.LOCK;
		}

		return TournamentIconType.DISABLED;
	}

	private getCompleteTournamentIconType(isSelected: boolean, prediction?: IPrediction) {
		const isCorrect = prediction?.isCorrect;

		if (isSelected) {
			if (prediction?.isAuto) {
				if (isCorrect) {
					return TournamentIconType.AUTO_SUCCESS;
				}

				return TournamentIconType.AUTO_WRONG;
			}

			if (isCorrect) {
				return TournamentIconType.SUCCESS;
			}

			return TournamentIconType.WRONG;
		}

		return TournamentIconType.EMPTY;
	}

	getTournamentIconType(
		tournament: ITournament,
		squadId: number,
		prediction?: IPrediction
	): TournamentIconType {
		const isSelected = prediction?.squadId === squadId;

		if (tournament.status === MatchStatus.Scheduled) {
			return this.getScheduledTournamentIconType(isSelected);
		}

		if (tournament.status === MatchStatus.Playing) {
			return this.getPlayingTournamentIconType(isSelected);
		}

		return this.getCompleteTournamentIconType(isSelected, prediction);
	}

	getTournamentById(id: number) {
		return flatMap(this._roundsStore.list, (round) => round.tournaments).find(
			(tournament) => tournament.id === id
		);
	}

	getIsShowOddsTournament(tournament: ITournament, isShowMatchOdds: boolean) {
		// Hide odds if match has live or complete status
		if (tournament?.status !== MatchStatus.Scheduled) {
			return false;
		}

		if (!tournament.awayOdds || !tournament.homeOdds) {
			return false;
		}

		return isShowMatchOdds;
	}

	private getStreakCompleteTournamentIconType(
		isSelected: boolean,
		prediction?: IStreakPrediction
	) {
		const isCorrect = prediction?.isCorrect;

		if (!prediction) {
			return TournamentIconType.SKIPPED;
		}

		if (isSelected) {
			if (isCorrect) {
				return TournamentIconType.SUCCESS;
			}

			return TournamentIconType.WRONG;
		}

		return TournamentIconType.EMPTY;
	}

	getStreakTournamentIconType(
		tournament: ITournament,
		squadId: number,
		prediction?: IStreakPrediction
	): TournamentIconType {
		const isSelected = prediction?.squadId === squadId;

		if (tournament.status === MatchStatus.Scheduled) {
			return this.getScheduledTournamentIconType(isSelected);
		}

		if (tournament.status === MatchStatus.Playing) {
			return this.getPlayingTournamentIconType(isSelected);
		}

		return this.getStreakCompleteTournamentIconType(isSelected, prediction);
	}

	getStreakTournamentDataById(tournamentId: number | null, prediction?: IStreakPrediction) {
		const tournament = get(this._roundsStore.selectedRound, "tournaments", []).find(
			(tournament) => tournament.id === tournamentId
		);

		if (!tournament) {
			return null;
		}

		const homeSquad = this._squadsStore.getSquadById(tournament.homeSquadId);
		const awaySquad = this._squadsStore.getSquadById(tournament.awaySquadId);
		const date = DateTime.fromISO(tournament.date);

		const homeIconType = this.getStreakTournamentIconType(
			tournament,
			tournament.homeSquadId,
			prediction
		);

		const awayIconType = this.getStreakTournamentIconType(
			tournament,
			tournament.awaySquadId,
			prediction
		);

		const isDisabled = tournament.status !== MatchStatus.Scheduled;
		const homeSquadIsChecked = prediction?.squadId === tournament.homeSquadId;
		const awaySquadIsChecked = prediction?.squadId === tournament.awaySquadId;
		const isShowLadderPosition = this.getIsShowLadderPosition(tournament.type);

		return {
			...tournament,
			date: date.toFormat(`ccc, d'${nth(date.day)}' LLL h'.'mm a`),
			isDisabled,
			homeSquad: {
				...homeSquad,
				selected: Math.round(tournament.homePicksPercentage),
				position: get(homeSquad?.ladder, "position", 0),
				Icon: tournamentIcons[homeIconType],
				color: homeSquad?.color,
				isChecked: homeSquadIsChecked,
				title: this.i18n.t(tournamentTitle[homeIconType]),
			},
			awaySquad: {
				...awaySquad,
				selected: Math.round(tournament.awayPicksPercentage),
				position: get(awaySquad?.ladder, "position", 0),
				Icon: tournamentIcons[awayIconType],
				color: awaySquad?.color,
				isChecked: awaySquadIsChecked,
				title: this.i18n.t(tournamentTitle[awayIconType]),
			},
			isComplete: tournament.status === MatchStatus.Complete,
			isPlaying: tournament.status === MatchStatus.Playing,
			isScheduled: tournament.status === MatchStatus.Scheduled,
			isShowLadderPosition,
			hasTeamSelected: homeSquadIsChecked || awaySquadIsChecked,
		} as ITournamentData;
	}
}
