export const SportbetCurrency = "$";
export const SportbetLink = "https://www.sportsbet.com.au/";
export const SportbetAgeLimit = 18;

//  Note: Disabling ESLint for template strings since ${GDPR} and ${GDPR_CONSENT_755}
//  appear to be special placeholders expected by DoubleClick's tracking system.
//  These likely get replaced server-side rather than in our JavaScript.

/* eslint-disable no-template-curly-in-string */
export const SportsbetMultiBuilder =
	"https://ad.doubleclick.net/ddm/clk/607911238;415367323;g;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755}";
export const SportsbetMultiH2HOdds =
	"https://ad.doubleclick.net/ddm/clk/607911238;415542278;k;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755}";
/* eslint-enable no-template-curly-in-string */

export const SportsbetMultiH2HBTYB = "https://ad.doubleclick.net/ddm/clk/574613132;383622152;z";
export const SportsbetMultiSplash = "https://ad.doubleclick.net/ddm/clk/582727752;391188119;v";
