import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {IconButton} from "@mui/material";
import {IBurgerNavItem} from "data/types/navigation";
import {BurgerNav} from "views/components/header/burger_nav/burger_nav.component";
import {ReactComponent as MenuIcon} from "assets/img/icons/menu.svg";

const Wrapper = styled.header`
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 10;
`;

const MenuButton = styled(IconButton)`
	margin-top: 16px;
	margin-left: 8px;

	path {
		fill: #ffffff;
	}
`;

interface IHeader {
	burgerNavItems: IBurgerNavItem[];
	isOpen: boolean;
	toggleMenu: () => void;
	closeHandler: () => void;
	menuButtonRef?: React.RefObject<HTMLButtonElement>;
	closeButtonRef: React.RefObject<HTMLButtonElement>;
}

export const MobileHeader: React.FC<IHeader> = observer(
	({burgerNavItems, isOpen, toggleMenu, closeHandler, menuButtonRef, closeButtonRef}) => {
		return (
			<Wrapper>
				<MenuButton onClick={toggleMenu} ref={menuButtonRef}>
					<MenuIcon />
				</MenuButton>

				<BurgerNav
					isOpen={isOpen}
					closeHandler={closeHandler}
					items={burgerNavItems}
					closeButtonRef={closeButtonRef}
				/>
			</Wrapper>
		);
	}
);
